import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const Credentials = ({ credentials, badges }) => {
  const creds = credentials.split("|").map((s, i) => (
    <div
      key={`cred-${i}`}
      className="italic text-xs md:text-sm text-gy-700 mb-1"
    >
      {s}
    </div>
  ))

  return badges ? (
    <div className="my-6">
      <div className="w-11/12 md:w-1/2 md:max-w-md">
        <GatsbyImage alt="Franklin Method certifications" image={badges} />
      </div>
      <div className="mt-5">{creds}</div>
    </div>
  ) : (
    <>{creds}</>
  )
}

export default Credentials
