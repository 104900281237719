import React from "react"
import Credentials from "./credentials"

const Biography = ({ bio, badges }) => {
  const {
    frontmatter: { name, credentials, certified },
    html,
  } = bio

  return (
    <div className="mb-12 md:mb-16 last:mb-0">
      <h4>{name}</h4>
      <Credentials
        credentials={credentials}
        badges={!!certified ? badges : null}
      />
      <div className="mt-5" dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  )
}

export default Biography
