import React from "react"
import useBiographiesQuery from "../../hooks/use-biographies-query"
import Biography from "./biography"
import useBadgesQuery from "../../hooks/use-badges-query"

const BiographyList = () => {
  const data = useBiographiesQuery()
  const badges = useBadgesQuery()

  const bios = data.map(({ node: { childMarkdownRemark } }, i) => (
    <Biography key={`bio-${i}`} bio={childMarkdownRemark} badges={badges} />
  ))
  return <>{bios}</>
}

export default BiographyList
