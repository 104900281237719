import React from "react"
import { graphql } from "gatsby"
import BaseAbout from "../../components/layout/page/base-about"
import BiographyList from "../../components/biography-list/biography-list"

const WhoWeAreAbout = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: { title },
    },
  } = data

  return (
    <BaseAbout title={title}>
      <BiographyList />
    </BaseAbout>
  )
}

export default WhoWeAreAbout
//
export const query = graphql`
  query WhoWeAreQuery {
    markdownRemark(frontmatter: { name: { eq: "who-we-are" } }) {
      frontmatter {
        title
      }
    }
  }
`
